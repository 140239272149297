import React from "react";
import { useHydrated } from "remix-utils/use-hydrated";
import { getTheme } from "~/components/theme-switcher/theme-utils";

export const useTheme = () => {
	const hydrated = useHydrated();

	if (!hydrated) {
		return null;
	}

	return getTheme();
};

export const NonceContext = React.createContext<string>("");
export const NonceProvider = NonceContext.Provider;
export const useNonce = () => React.useContext(NonceContext);

// @see https://usehooks.com/useLockBodyScroll.
export function useLockBody() {
	React.useLayoutEffect((): (() => void) => {
		const originalStyle: string = window.getComputedStyle(
			document.body,
		).overflow;
		document.body.style.overflow = "hidden";
		return () => (document.body.style.overflow = originalStyle);
	}, []);
}
